import React, { Component } from "react";
import { Navbar } from "react-bootstrap";
import "./App.css";
import twitter from "./resources/twitter.png";
import dotDotDot from "./resources/dotDotDot.mp4";

const HEADER_SIZE = 30;
const HEADER_MARGIN = 24;
const ICON_SIZE = 20;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShowing: false,
      width: window.innerWidth,
      height: window.innerHeight,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    console.log("height: ", window.innerHeight);
    console.log("width: ", window.innerWidth);
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  onClickDots = () => {
    console.log("TAKITO");
    // window.location.href = "mailto:buildwith@teknique.xyz?subject=Subject&body=message%20goes%20here";
    window.location.href = "mailto:buildwith@teknique.xyz";
  };

  render() {
    return (
      <div
        style={{
          backgroundColor: "#000000",
          display: "flex",
          flexDirection: "column",
          height: this.state.height,
          width: this.state.width,
        }}
      >
        <div
          style={{
            backgroundColor: "#000000",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            width: "100%",
            height: HEADER_SIZE,
          }}
        >
          <div style={{ display: "flex", marginLeft: 16, marginTop: 24 }}>
            <Navbar.Brand
              style={{
                color: "#dadada",
              }}
            >
              Teknique
            </Navbar.Brand>
            <Navbar.Brand>
              <a
                style={{ display: "flex", marginLeft: 16 }}
                href="https://twitter.com/buildwteknique"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt={""}
                  src={twitter}
                  style={{ opacity: 0.8 }}
                  height={ICON_SIZE + 1}
                />
              </a>
            </Navbar.Brand>
          </div>
          <div
            style={{
              color: "#dadada",
              display: "flex",
              fontSize: 18,
              height: HEADER_SIZE,
              marginRight: 24,
              marginTop: 20,
            }}
          >
            {/* CONNECT SOON */}
          </div>
        </div>
        <div
          style={{
            alignItems: "flex-start",
            display: "flex",
            justifyContent: "center",
            marginTop: this.state.height * 0.16,
            height: this.state.height - HEADER_SIZE - HEADER_MARGIN,
            width: this.state.width,
          }}
        >
          <div
            style={{
              color: "#dadada",
              display: "flex",
              fontSize: 50,
            }}
          >
            web3, blockchain, & nft consulting
          </div>
        </div>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            marginTop: HEADER_SIZE + 20,
            height: this.state.height - 2 * (HEADER_SIZE + HEADER_MARGIN),
            justifyContent: "center",
            width: this.state.width,
            position: "absolute",
          }}
        >
          <div
            className="dotDotDot"
            onClick={this.onClickDots}
            style={{
              color: "#dadada",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              overflow: "hidden",
              borderRadius: 100,
              width: this.state.width > 700 ? 600 : this.state.width * 0.9,
              height: 150,
            }}
          >
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                color: "#dadada",
                display: "flex",
                fontSize: this.state.width > 700 ? 40 : 30,
                textAlign: "center",
                width: this.state.width > 700 ? 600 : 500,
              }}
            >
              build with teknique
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
